import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import moment, { max } from 'moment';
import SimpleBar from 'simplebar';
import Tooltip from '@material-ui/core/Tooltip';

export const BEARER_TOKEN = window.getCookie('token') ? window.getCookie('token') : process.env.REACT_APP_JWT_TOKEN ? process.env.REACT_APP_JWT_TOKEN : '123'

const TOOLTIP_TEXT = "Bookmark this session to receive an email with event info."

class App extends React.Component {
  constructor(props) {
    super(props)

    this.firstRun = true

    let timeHourOptions   = []
    let selectHours = []
    let selectMinutes = []
    let timeMinuteOptions = [
      <option>00</option>,
      <option>15</option>,
      <option>30</option>,
      <option>45</option>
    ]

    for (var i = 0; i < 24; i++) {
      timeHourOptions.push(<option>{ i < 10 ? `0${i}` : i }</option>)
      selectHours.push({
        value: i < 10 ? `0${i}` : i,
        label: i < 10 ? `0${i}` : i
      })
    }

    for (var i = 0; i < 60; i = i + 5) {
      selectMinutes.push({
        value: i < 10 ? `0${i}` : i,
        label: i < 10 ? `0${i}` : i
      })
    }

    this.state = {
      isLoading: true,
      isCreateLoading: false,
      isCreateSuccess: false,
      createErrorMessage: '',
      erroneousFields: [],
      view: 'all',
      categories: [],
      topics: [],
      channels: [],
      representatives: [],
      selectDays: [],
      selectTopics: [],
      selectCategories: [],
      selectParticipants: [],
      selectHours,
      selectMinutes,
      selectedOptionTopic: null,
      selectedOptionStartHour: null,
      selectedOptionStartMinute: null,
      selectedOptionEndHour: null,
      selectedOptionEndMinute: null,
      selectedOptionCategory: null,
      categoryOptions: [],
      topicOptions: [],
      channelOptions: [],
      representativeOptions: [],
      filterCategories: null,
      filterTopics: null,
      filterChannels: null,
      filterRepresentatives: null,
      day: null,
      selectedDays: [],
      days: [],
      entryNumberingHeight: 200,
      layout: [],
      selectedEvent: {
        ID: null
      },
      starredEvents: [],
      create: {
        date: '',
        dateStartHour: '',
        dateStartMinute: '',
        dateEndHour: '',
        dateEndMinute: '',
        topic: '',
        category: '',
        participants: [],
        title: '',
        description: ''
      },
      timeHourOptions,
      timeMinuteOptions
    }
  }
  componentDidMount() {
    this.getData()
  }
  getData() {
    axios.get(
      `/wp-json/calendar/v1/get?token=${BEARER_TOKEN}`,
      {
        headers: {
          'Authorization': `Bearer ${BEARER_TOKEN}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ).then(response => {
      console.log('calendar response', response.data)
      if (typeof response.data !== 'undefined') {

        let firstDayToShow = ''
        let eventDays = []
        let categories = []
        let topics = []
        let channels = []
        let representatives = []
        let days = []
        let starredEvents = []

        if (typeof response.data.starred_events) {
          response.data.starred_events.forEach((event) => {
            starredEvents[event] = true
          })
        }

        if (typeof response.data.day_show_first !== 'undefined') {
          firstDayToShow = response.data.day_show_first
        }

        if (typeof response.data.event_days !== 'undefined') {
          Object.keys(response.data.event_days).forEach((day) => {
            eventDays.push(response.data.event_days[day])
          })
        }
        if (typeof response.data.categories !== 'undefined') {
          Object.keys(response.data.categories).forEach((category) => {
            categories.push(category)
          })
        }
        if (typeof response.data.topics !== 'undefined') {
          Object.keys(response.data.topics).forEach((topic) => {
            topics.push(topic)
          })
        }
        if (typeof response.data.channels !== 'undefined') {
          Object.keys(response.data.channels).forEach((channel) => {
            channels.push(channel)
          })
        }
        if (typeof response.data.representatives !== 'undefined') {
          Object.keys(response.data.representatives).forEach((representative) => {
            representatives.push(response.data.representatives[representative])
          })
        }
        if (typeof response.data.days !== 'undefined') {
          days = response.data.days
        }

        this.setState({
          eventDays,
          categories,
          topics,
          channels,
          representatives,
          days,
          starredEvents
        }, () => {
          this.getParticipants().then(() => {
            this.setCurrentView(this.state.days)
            this.buildOptions()
            this.setState({
              isLoading: false
            })
          })
        })
      }
    }).catch(error => {
      console.log('calendar catch', error)
    })
  }
  getParticipants() {
    return new Promise((resolve, reject) => {

      axios.get(
        `/wp-json/directory/v1/list?token=${BEARER_TOKEN}`,
        {
          headers: {
            'Authorization': `Bearer ${BEARER_TOKEN}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(response => {
        console.log('participant list data', response.data)
        if (typeof response.data !== 'undefined' && typeof response.data.directory !== 'undefined') {
          this.setState({
            participants: response.data.directory
          }, () => {
            resolve()
            return
          })
        } else {
          alert('Participants could not be retrieved.')
          reject()
        }
      })
    })
  }
  buildOptions() {
    let categoryOptions = []
    let selectCategories = []
    let topicOptions = []
    let channelOptions = []
    let selectTopics = []
    let selectDays = []
    let selectParticipants = []
    let representativeOptions = []

    categoryOptions.push({ value: null, label: 'All Timezones' })
    topicOptions.push({ value: null, label: 'All Topics' })
    channelOptions.push({ value: null, label: 'All Channels' })
    representativeOptions.push({ value: null, label: 'All Speakers' })

    this.state.categories.forEach((category) => {
      categoryOptions.push({ value: category, label: category })
    })
    console.log('this.state.eventDays', this.state.eventDays)
    this.state.eventDays.forEach((day) => {
      selectDays.push({ value: day, label: day })
    })
    this.state.categories.forEach((category) => {
      selectCategories.push({ value: category, label: category })
    })
    this.state.topics.forEach((topic) => {
      topicOptions.push({ value: topic, label: topic })
    })
    this.state.channels.forEach((channel) => {
      channelOptions.push({ value: channel, label: channel })
    })
    this.state.topics.forEach((topic) => {
      selectTopics.push({ value: topic, label: topic })
    })
    this.state.participants.forEach((participant) => {
      if (participant.company) {
        selectParticipants.push({ value: participant.id, label: `${participant.first_name} ${participant.last_name} (${participant.company})` })
      } else {
        selectParticipants.push({ value: participant.id, label: `${participant.first_name} ${participant.last_name}` })
      }
    })
    this.state.representatives.forEach((representative) => {
      representativeOptions.push({ value: representative.ID, label: `${ representative.first_name } ${ representative.last_name }` })
    })

    this.setState({
      categoryOptions,
      topicOptions,
      channelOptions,
      selectDays,
      selectTopics,
      selectCategories,
      selectParticipants,
      representativeOptions
    })
  }
  setCurrentView(days) {
    console.log('calendar debug: 0')
    let currentView = []

    if (!days) {
      days = this.state.selectedDays
    }

    let i = 0

    days.forEach((day) => {
      currentView.push(<div className={`calendar-outer`}>
        <strong className={`calendar-title`}>{ moment(`${day.year}-${day.month}-${day.day}T00:00:00.000Z`).utc().format('ddd, DD. MMM YY') }</strong>
        <div id={`day-${i}`} className={`calendar`}>
          {/* PLACEHOLDER: DayPilot will be inserted here */}
        </div>
      </div>)

      i++
    })
    
    console.log('calendar debug: 1', days)

    this.setState({
      currentView,
      selectedDays: days
    }, () => {

      console.log('calendar debug: 2', days)

      // Go through each day and init DayPilot and add each event.
      /*eslint-disable */
      days.forEach((day, index) => {
        var dp = new DayPilot.Calendar(`day-${index}`);
        dp.startDate = `${day.year}-${day.month}-${day.day}`
        dp.viewType = "Day";
        dp.timeFormat = "Clock24Hours";
        dp.businessBeginsHour = day.entries[0].startHour;
        dp.init();

        console.log('calendar debug: 3', `${day.year}-${day.month}-${day.day}`)

        // Add events to DayPilot.
        day.entries.forEach((entry) => {
          console.log('calendar debug: 4', entry)
          
          // let item = <div>
          //   <strong className={`entry-title`} style={ event.length < 0.25 ? { fontSize: 12 } : null }>{ event.title }</strong>
          //   <p className={`entry-copy`}>{ `${event.copy}` }</p>
          //   <em className={`entry-meta`} style={ event.length < 0.25 ? { display: 'none' } : null }>{ event.meta }</em>
          //   <button className={`entry-starred ${typeof this.state.starredEvents[event.ID] !== 'undefined' && this.state.starredEvents[event.ID] ? 'active' : ''}`} onClick={(ev) => { ev.stopPropagation(); this.toggleEventStarred(event.ID) }}>Star</button>
          //   <div className={`entry-overlay`}>
          //     <button className={`entry-overlay-close`} onClick={(ev) => { ev.stopPropagation(); this.setSelectedEvent({ ID: null }) }}>Close</button>
          //     <button className={`entry-overlay-starred ${typeof this.state.starredEvents[event.ID] !== 'undefined' && this.state.starredEvents[event.ID] ? 'active' : ''}`} onClick={(ev) => { ev.stopPropagation(); this.toggleEventStarred(event.ID) }}>Star</button>
          //     <strong className={`entry-overlay-title`}>{ event.title }</strong>
          //     <span className={`entry-overlay-row entry-overlay-row-double`}>
          //       { event.start && event.start.year ? <span>{ moment(`${event.start.year}-${event.start.month}-${event.start.day}T00:00:00.000Z`).utc().format('DD. MMM YY') }</span> : null }
          //       { event.meta ? <span>{ event.meta }</span> : null }
          //     </span>
          //     {
          //       event.categories && event.categories.length > 0 ? <span className={`entry-overlay-row`}>
          //         { categoriesList }
          //       </span>
          //       : null
          //     }
          //     {
          //       representativeList ?
          //         <span className={`entry-overlay-row`}>
          //           { representativeList } 
          //         </span>
          //       : null
          //     }
          //     {
          //       event.attendeeCount ?
          //         <span className={`entry-overlay-row entry-overlay-row-double`}>
          //           <span>{ event.attendeeCount } participant(s)</span>
          //         </span>
          //       : null
          //     }
          //     {
          //       event.image ? <img src={ event.image } /> : null
          //     }
          //     <p style={{ whiteSpace: 'pre-line' }}>{ event.copy }</p>
          //     {/* <ul>
          //       { debugOverlappedEvents}
          //     </ul> */}
          //     <div className={`entry-overlay-actions`}>
          //       <a href={ event.link } className={`button`}>Session page</a>
          //     </div>
          //   </div>
          // </div>

          let representatives = ''
          let representativeList = ``

          entry.representatives.forEach((representative) => {
            representativeList += `<span>${ representative.first_name } ${ representative.last_name }</span>`
            if (representatives !== '') {
              representatives +=  ',' + representative.ID
            } else {
              representatives += representative.ID
            }
          })

          let categoriesList = ``

          entry.categories.forEach((category) => {
            categoriesList += `<span>${ category }</span>`
          })
          
          var e = new DayPilot.Event({
            start: new DayPilot.Date(`${day.year}-${day.month}-${day.day}T${entry.startHour}:${entry.startMinute}:00`),
            end: new DayPilot.Date(`${day.year}-${day.month}-${day.day}T${entry.endHour}:${entry.endMinute}:00`),
            id: entry.ID,
            text: `
              <strong class="${`entry-title`}">${ entry.title }</strong>
              <p class="${`entry-copy`}">${entry.copy}</p>
              <em class="${`entry-meta`}">${ entry.meta }</em>
              <button data-id="${entry.ID}" class="${`entry-starred ${typeof this.state.starredEvents[entry.ID] !== 'undefined' && this.state.starredEvents[entry.ID] ? 'active' : ''}`}">Star</button>
              <div class="${`entry-overlay`}">
                <button class="${`entry-overlay-close`}">Close</button>
                <button data-id="${entry.ID}" class="${`entry-overlay-starred ${typeof this.state.starredEvents[entry.ID] !== 'undefined' && this.state.starredEvents[entry.ID] ? 'active' : ''}`}"><span>${TOOLTIP_TEXT}</span></button>
                <strong class="${`entry-overlay-title`}">${ entry.title }</strong>
                <span class="${`entry-overlay-row entry-overlay-row-double`}">
                  ${ entry.start && entry.start.year ? `<span>${ moment(`${entry.start.year}-${entry.start.month}-${entry.start.day}T00:00:00.000Z`).utc().format('DD. MMM YY') }</span>` : `` }
                  ${ entry.meta ? `<span>${ entry.meta }</span>` : `` }
                </span>
                ${
                  entry.categories && entry.categories.length > 0 ? `<span class="${`entry-overlay-row`}">${ categoriesList }</span>`
                  : ``
                }
                ${
                  representativeList ? `<span class="${`entry-overlay-row`}">${ representativeList }</span>`
                  : ``
                }
                ${
                  entry.attendeeCount ?
                    <span class={`entry-overlay-row entry-overlay-row-double`}>
                      <span>{ entry.attendeeCount } participant(s)</span>
                    </span>
                  : ``
                }
                ${
                  entry.image ? <img src={ entry.image } /> : ``
                }
                <p style="white-space: pre-line">${ entry.copy }</p>
                <div class="${`entry-overlay-actions`}">
                  <a href="${ entry.link }" class="${`button`}">Session page</a>
                </div>
              </div>
            `
          });
          dp.events.add(e);
          console.log('calendar debug: 5')
        })
      })
      /*eslint-enable */

      // Add event listeners for starring events, opening overlays etc.
      let elements = document.querySelectorAll('.calendar_default_event');
      elements.forEach((element) => {
        let ID = element.getAttribute('data-id')
        if (!ID) {
          ID = element.querySelector('[data-id]').getAttribute('data-id')
        }
        let starElements = element.querySelectorAll('.entry-starred, .entry-overlay-starred')

        element.addEventListener('click', (ev) => {
          ev.stopPropagation()

          this.setSelectedEvent({ ID })
        })

        starElements.forEach((_element) => {
          _element.addEventListener('click', (ev) => {
            ev.stopPropagation();
            this.toggleEventStarred(ID)
          })
        })

        let closeElements = element.querySelectorAll('.entry-overlay-close')

        closeElements.forEach((_element) => {
          _element.addEventListener('click', (ev) => {
            ev.stopPropagation();
            this.setSelectedEvent({ ID: null })
          })
        })
      })
      
      // document.querySelectorAll('.calendar > div').forEach((element) => {
      //   new SimpleBar(element);
      // })
      // Auto-scroll to first event of the day or 9am (if not events exist yet) for better UX
      // if (this.firstRun) {
      //   this.firstRun = false
      //   document.querySelectorAll('.calendar').forEach((element) => {
      //     let id = element.getAttribute('data-id');
      //     let firstEvent = null;
      //     let entries = document.querySelectorAll(`[data-id="${id}"] .entry`)
      //     let earliestStartingHour = 24;

      //     entries.forEach((entry) => {
      //       if (entry.getAttribute('data-hour') <= earliestStartingHour) {
      //         earliestStartingHour = entry.getAttribute('data-hour')
      //       }
      //     })

      //     if (entries.length > 0) {
      //       if (earliestStartingHour > 0) { earliestStartingHour = earliestStartingHour - 1 }
      //       element.scrollTop = this.state.entryNumberingHeight * earliestStartingHour
      //     } else {
      //       element.scrollTop = this.state.entryNumberingHeight * 9
      //     }
      //   })
      // }
    })
  }
  layout(day) {
    let preppedEvents = []
    let layout = []
    let allEvents = []
    let eventsByHour = []
    let eventsByHourMinute = []
    let maxOverlappedEvents = 0

    if (!day) {
      day = this.state.day
    } else {
      this.setState({
        day
      })
    }

    // PREP STAGE 1
    if (!day || !day.entries) {
      return
    }

    try {
      day.entries.map((event) => {
        event.endHour = parseFloat(event.endHour)
        event.startHour = parseFloat(event.startHour)
        event.endMinute = parseFloat(event.endMinute)
        event.startMinute = parseFloat(event.startMinute)
        event.length = (event.endHour + (event.endMinute / 60)) - (event.startHour + (event.startMinute / 60))
  
        for (var i = 0; i < event.length; i = i + 0.25) {
          if (typeof eventsByHour[event.startHour + i] === 'undefined') { eventsByHour[event.startHour + i] = [] }
          if (typeof eventsByHourMinute[event.startHour + i] === 'undefined') { eventsByHourMinute[event.startHour + i] = [] }
          if (typeof eventsByHourMinute[event.startHour + i][event.startMinute + i] === 'undefined') { eventsByHourMinute[event.startHour + i][event.startMinute + i] = [] }
          allEvents.push(event)
          eventsByHour[event.startHour + i].push(event)
          eventsByHourMinute[event.startHour + i][event.startMinute + i].push(event)
        }
  
        preppedEvents.push(event)
      })
    } catch (err) {
      console.log('err', err)
      let event = day.entries
      event.length = event.endHour - event.startHour

      for (var i = 0; i < event.length; i = i + 0.25) {
        if (typeof eventsByHour[event.startHour + i] === 'undefined') { eventsByHour[event.startHour + i] = [] }
        if (typeof eventsByHourMinute[event.startHour + i] === 'undefined') { eventsByHourMinute[event.startHour + i] = [] }
        if (typeof eventsByHourMinute[event.startHour + i][event.startMinute + i] === 'undefined') { eventsByHourMinute[event.startHour + i][event.startMinute + i] = [] }

        allEvents.push(event)
        eventsByHour[event.startHour + i].push(event)
        eventsByHourMinute[event.startHour + i][event.startMinute + i].push(event)
      }

      preppedEvents.push(event)
    }

    // PREP STAGE 2
    preppedEvents.forEach((event, index) => {
      let overlappingEvents = []

      // Check how many events this events overlaps with based on HOUR (if any)
      allEvents.forEach((_event, _index) => {

        let intStartFirst = parseFloat(`${event.startHour.pad().toString()}${event.startMinute.pad().toString()}`)
        let intStartSecond = parseFloat(`${_event.startHour.pad().toString()}${_event.startMinute.pad().toString()}`)
        let intEndFirst = parseFloat(`${event.endHour.pad().toString()}${event.endMinute.pad().toString()}`)
        let intEndSecond = parseFloat(`${_event.endHour.pad().toString()}${_event.endMinute.pad().toString()}`)

        if (
          (intStartFirst > intStartSecond && intEndFirst < intEndSecond) ||  // (0000 > 1200) && (0230 < 1400) = false
          (intStartFirst < intEndSecond && intEndFirst > intStartSecond) ||  // (0000 > 1400) && (0230 < 1200) = false
          (intEndFirst > intStartSecond && intStartFirst <= intStartSecond)  // (0230 >= 1200) && (0000 <= 1200) = true
        ) {
          overlappingEvents[_event.ID] = _event
        }
      })

      if (event.ID == 713) {
        console.log('special debug 0', event, parseFloat(`${event.startHour.pad().toString()}${event.startMinute.pad().toString()}`), parseFloat(`${event.endHour.pad().toString()}${event.endMinute.pad().toString()}`), overlappingEvents)
      }

      event.overlappingEvents = overlappingEvents

      if (Object.keys(event.overlappingEvents).length > maxOverlappedEvents) {
        maxOverlappedEvents = Object.keys(event.overlappingEvents).length
      }

      preppedEvents[index] = event
    })

    let commonWidth = 100 / (maxOverlappedEvents)

    if (maxOverlappedEvents == 1) {
      commonWidth = 100
      console.log('maxOverlappedEvents', maxOverlappedEvents);
    }

    // Build event layout
    preppedEvents.forEach((event, index) => {
      let count = 0
      let debugOverlappedEvents = []
      let overlappedIndex = 0

      let i = 0
      event.overlappingEvents.forEach((_event) => {
        if (_event !== 'null') {
          count++

          if (_event.ID == event.ID) {
            overlappedIndex = i
          }

          debugOverlappedEvents.push(<li>{ _event.ID }</li>)

          i++
        }
      })

      let shouldExclude = null

      if (this.state.filterCategories) {
        let found = false

        if (event.categories) {
          event.categories.forEach((category) => {
            if (this.state.filterCategories === category) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }
      if (this.state.filterTopics) {
        let found = false

        if (event.topics) {
          event.topics.forEach((topic) => {
            if (this.state.filterTopics === topic) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }
      if (this.state.filterChannels) {
        let found = false

        if (event.channels) {
          event.channels.forEach((channel) => {
            if (this.state.filterChannels === channel) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }
      if (this.state.filterRepresentatives) {
        let found = false

        if (event.representatives) {
          event.representatives.forEach((rep) => {
            if (parseFloat(this.state.filterRepresentatives) === rep.ID) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }

      let representatives = ''
      let representativeList = []

      event.representatives.forEach((representative) => {
        representativeList.push(<span>{ representative.first_name } { representative.last_name }</span>)
        if (representatives !== '') {
          representatives +=  ',' + representative.ID
        } else {
          representatives += representative.ID
        }
      })

      let categoriesList = []

      event.categories.forEach((category) => {
        categoriesList.push(<span>{ category }</span>)
      })

      if (!shouldExclude) {
        event.startHour = parseFloat(event.startHour)
        event.startMinute = parseFloat(event.startMinute)

        let calcTop = this.state.entryNumberingHeight * (event.startHour + (event.startMinute / 60))
        let calcHeight = this.state.entryNumberingHeight * event.length
        let left = commonWidth * overlappedIndex
        let commonLeft = commonWidth
        let isNowOrFuture = Date.parse(`${day.year}-${day.month}-${day.day} ${event.endHour}:${event.endMinute}`) - Date.parse(new Date()) > 0

        layout.push(<li onClick={(ev) => { ev.stopPropagation(); this.setSelectedEvent(event) }} data-id={`${event.ID}`} data-category={ event.categories } data-topic={ event.topics } data-representatives={ representatives } data-hour={ event.startHour } data-hour={ event.startHour } data-minute={ event.startMinute } data-day={ day.day } data-month={ day.month } data-year={ day.year } data-time={ event.time } data-entry-calendar={`${day.year}-${day.month}-${day.day}`} className={`entry entry-height-${event.length.toString().replace('.', '-')} entry-overlapped-index-${overlappedIndex} entry-overlapped-${count} ${this.state.selectedEvent.ID == event.ID ? `entry-selected` : ``} ${isNowOrFuture ? `valid` : `expired`} ${(this.state.view == 'global' && event.private) || (this.state.view == 'personal' && !event.private) ? `inactive` : ``}`} data-common-left={ commonLeft } style={{ top: calcTop, height: calcHeight, left: `${left}%`, width: `${ commonWidth - 1 }%` }}>
          <div className={`entry-debug`}>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <strong className={`entry-title`} style={ event.length < 0.25 ? { fontSize: 12 } : null }>{ event.title }</strong>
          <p className={`entry-copy`}>{ `${event.copy}` }</p>
          <em className={`entry-meta`} style={ event.length < 0.25 ? { display: 'none' } : null }>{ event.meta }</em>
          <button className={`entry-starred ${typeof this.state.starredEvents[event.ID] !== 'undefined' && this.state.starredEvents[event.ID] ? 'active' : ''}`} onClick={(ev) => { ev.stopPropagation(); this.toggleEventStarred(event.ID) }}>Star</button>
          <div className={`entry-overlay`}>
            <button className={`entry-overlay-close`} onClick={(ev) => { ev.stopPropagation(); this.setSelectedEvent({ ID: null }) }}>Close</button>
            <button className={`entry-overlay-starred ${typeof this.state.starredEvents[event.ID] !== 'undefined' && this.state.starredEvents[event.ID] ? 'active' : ''}`} onClick={(ev) => { ev.stopPropagation(); this.toggleEventStarred(event.ID) }}>Star</button>
            <strong className={`entry-overlay-title`}>{ event.title }</strong>
            <span className={`entry-overlay-row entry-overlay-row-double`}>
              { event.start && event.start.year ? <span>{ moment(`${event.start.year}-${event.start.month}-${event.start.day}T00:00:00.000Z`).utc().format('DD. MMM YY') }</span> : null }
              { event.meta ? <span>{ event.meta }</span> : null }
            </span>
            {
              event.categories && event.categories.length > 0 ? <span className={`entry-overlay-row`}>
                { categoriesList }
              </span>
              : null
            }
            {
              representativeList ?
                <span className={`entry-overlay-row`}>
                  { representativeList } 
                </span>
              : null
            }
            {
              event.attendeeCount ?
                <span className={`entry-overlay-row entry-overlay-row-double`}>
                  <span>{ event.attendeeCount } participant(s)</span>
                </span>
              : null
            }
            {
              event.image ? <img src={ event.image } /> : null
            }
            <p style={{ whiteSpace: 'pre-line' }}>{ event.copy }</p>
            {/* <ul>
              { debugOverlappedEvents}
            </ul> */}
            <div className={`entry-overlay-actions`}>
              <a href={ event.link } className={`button`}>Session page</a>
            </div>
          </div>
        </li>)
      }
    })

    return layout
  }
  toggleEventStarred(ID) {
    let starredEvents = this.state.starredEvents

    if (typeof starredEvents[ID] !== 'undefined' && starredEvents[ID]) {
      // Unstar
      starredEvents[ID] = false
    } else {
      // Star
      starredEvents[ID] = true
    }

    var form_data = new FormData();

    starredEvents.forEach((event, index) => {
      if (event) {
        form_data.append(`event[${index}]`, true);
      }
    })

    // Update user prefs
    axios.post(
      `/wp-json/calendar/v1/update/starred?token=${BEARER_TOKEN}`,
      form_data,
      {
        headers: {
          'Authorization': `Bearer ${BEARER_TOKEN}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )

    document.querySelectorAll(`[data-id].entry-starred, [data-id].entry-overlay-starred`).forEach((event) => {
      event.classList.remove('active')
    })

    starredEvents.forEach((bool, id) => {
      if (bool) {
        document.querySelectorAll(`[data-id="${id}"].entry-starred, [data-id="${id}"].entry-overlay-starred`).forEach((event) => {
          event.classList.add('active')
        })
      }
    })

    this.setState({
      starredEvents
    }, () => {
      // this.setCurrentView()
    })
  }
  setSelectedEvent(event) {
    document.querySelectorAll(`[data-id].entry-selected`).forEach((element) => {
      element.classList.remove('entry-selected')
    })
    
    this.setState({
      selectedEvent: event
    }, () => {
      if (event.ID) {
        document.querySelector(`[data-id="${event.ID}"]`).classList.add('entry-selected')
      }
      // this.setCurrentView()
    })
  }
  filter(type, value) {
    console.log('filter', type, value)
    let filterCategories = this.state.filterCategories
    let filterTopics = this.state.filterTopics
    let filterChannels = this.state.filterChannels
    let filterRepresentatives = this.state.filterRepresentatives

    if (type === 'category') {
      filterCategories = value
    } else if (type === 'topic') {
      filterTopics = value
    } else if (type === 'channel') {
      filterChannels = value
    } else if (type === 'representative') {
      filterRepresentatives = value
    }

    let events = document.querySelectorAll(`.calendar_default_event`)
    events.forEach((event) => {
      event.classList.remove('filtered-out')
      event.classList.remove('filtered-in')
    })

    this.state.selectedDays.forEach((day) => {
      let entriesToInclude = []
      day.entries.forEach((event) => {
        console.log('event', event)

        let shouldExclude = null

        if (filterCategories) {
          let found = false

          if (event.categories) {
            event.categories.forEach((category) => {
              if (filterCategories === category) {
                found = true
              }
            })
          }

          if (found) {
            shouldExclude = shouldExclude ? true : false
          } else {
            shouldExclude = true
          }
        }
        if (filterTopics) {
          let found = false

          if (event.topics) {
            event.topics.forEach((topic) => {
              if (filterTopics === topic) {
                found = true
              }
            })
          }

          if (found) {
            shouldExclude = shouldExclude ? true : false
          } else {
            shouldExclude = true
          }
        }
        if (filterChannels) {
          let found = false

          if (event.channels) {
            event.channels.forEach((channel) => {
              if (filterChannels === channel) {
                found = true
              }
            })
          }

          if (found) {
            shouldExclude = shouldExclude ? true : false
          } else {
            shouldExclude = true
          }
        }
        if (filterRepresentatives) {
          let found = false

          if (event.representatives) {
            event.representatives.forEach((rep) => {
              if (parseFloat(filterRepresentatives) === rep.ID) {
                found = true
              }
            })
          }

          if (found) {
            shouldExclude = shouldExclude ? true : false
          } else {
            shouldExclude = true
          }
        }

        if (shouldExclude) {
          if (document.querySelector(`.calendar_default_event[data-id="${event.ID}"]`)) {
            document.querySelector(`.calendar_default_event[data-id="${event.ID}"]`).classList.add('filtered-out')
            document.querySelector(`.calendar_default_event[data-id="${event.ID}"]`).classList.remove('filtered-in')
          }
        }
      })
    })

    this.setState({
      filterCategories,
      filterTopics,
      filterChannels,
      filterRepresentatives
    }, () => {
      
      
    })
  }
  create(ev) {

    ev.preventDefault()

    // Reset any existing error flags
    this.setState({
      isCreateLoading: true,
      hasCreateError: false,
      createErrorMessage: ''
    })

    let hasCreateError = false
    let createErrorMessage = ''
    let erroneousFields = []

    let date            = this.state.create.date
    let dateStartHour   = this.state.create.dateStartHour
    let dateStartMinute = this.state.create.dateStartMinute
    let dateEndHour     = this.state.create.dateEndHour
    let dateEndMinute   = this.state.create.dateEndMinute
    let topic           = this.state.create.topic
    let category        = this.state.create.category
    let participants    = this.state.create.participants
    let title           = this.state.create.title
    let description     = this.state.create.description

    let dateStart = `${dateStartHour}:${dateStartMinute}`
    let dateEnd   = `${dateEndHour}:${dateEndMinute}`

    // Check for empty fields.
    if (!date || !dateStartHour || !dateStartMinute || !dateEndHour || !dateEndMinute || !topic || !category || !participants || !title || !description) {
      console.log('participants', participants)
      hasCreateError = true
      createErrorMessage = `There are mandatory fields missing.`

      if (!date) { erroneousFields.push('date') }
      if (!dateStartHour) { erroneousFields.push('dateStartHour') }
      if (!dateStartMinute) { erroneousFields.push('dateStartMinute') }
      if (!dateEndHour) { erroneousFields.push('dateEndHour') }
      if (!dateEndMinute) { erroneousFields.push('dateEndMinute') }
      if (!topic) { erroneousFields.push('topic') }
      if (!category) { erroneousFields.push('category') }
      if (!participants || participants.length == 0) { erroneousFields.push('participants') }
      if (!title) { erroneousFields.push('title') }
      if (!description) { erroneousFields.push('description') }

    } else {

      // Check end date is after start date
      if (parseFloat(`${parseFloat(dateStartHour).pad()}${parseFloat(dateStartMinute).pad()}`) >= parseFloat(`${parseFloat(dateEndHour).pad()}${parseFloat(dateEndMinute).pad()}`)) {
        hasCreateError = true
        createErrorMessage = `Start date must be before end date.`
      }
    }


    if (!hasCreateError) {

      var form_data = new FormData();

      form_data.append(`date`, date);
      form_data.append(`dateStart`, dateStart);
      form_data.append(`dateEnd`, dateEnd);
      form_data.append(`topic`, topic);
      form_data.append(`category`, category);
      form_data.append(`participants`, participants);
      form_data.append(`title`, title);
      form_data.append(`description`, description);

      axios.post(
        `/wp-json/calendar/v1/insert?token=${BEARER_TOKEN}`,
        form_data,
        {
          headers: {
            'Authorization': `Bearer ${BEARER_TOKEN}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then((response) => {
        if (typeof response.data.status !== 'undefined' && response.data.status == 'success') {
          this.getData()

          this.setState({
            create: {
              date: '',
              dateStartHour: '',
              dateStartMinute: '',
              dateEndHour: '',
              dateEndMinute: '',
              topic: '',
              category: '',
              participants: [],
              title: '',
              description: ''
            },
            isCreateLoading: false,
            isCreateSuccess: true,
            isLoading: true
          }, () => {
            setTimeout(() => {
              this.setState({
                showCreate: false,
                isCreateSuccess: false
              })
            }, 1500)
          })
        } else {
          alert('An unexpected error occurred. Please contact technical support with the following error code: c04950a.')
        }
      })
    } else {
      console.log('hasCreateError', hasCreateError, createErrorMessage)
      this.setState({
        isCreateLoading: false,
        hasCreateError,
        createErrorMessage,
        erroneousFields
      })
    }
  }
  setCreateInput(field, value) {
    let fields = this.state.create

    if (field == 'participants') {
      let newValue = []
      if (value) {
        value.forEach((option) => {
          newValue.push(option.value)
        })
      }
      value = newValue
    }

    fields[field] = value

    console.log('setCreateInput', fields, field, value)

    this.setState({
      create: fields
    })
  }
  previous() {
    let parent = document.querySelector('.calendar-parent')
    let element = document.querySelector('.calendar-global-container .calendar-inner')
    let currentScrollLeft = element.scrollLeft
    let scrollLeft = 0

    if (currentScrollLeft == 0) {
      // don't do anything
      scrollLeft = 0
    } else if (currentScrollLeft > parent.clientWidth) {
      let currentIndex = Math.ceil(currentScrollLeft / parent.clientWidth)
      scrollLeft = ((currentIndex - 1) * parent.clientWidth) - (75 * (currentIndex - 1))
    } else {
      // default to start
      scrollLeft = 0
    }

    element.scrollLeft = scrollLeft
  }
  next() {
    let parent = document.querySelector('.calendar-parent')
    let element = document.querySelector('.calendar-global-container .calendar-inner')
    let currentScrollLeft = element.scrollLeft
    let scrollLeft = 0

    if (currentScrollLeft == 0) {
      console.log('Scenario A');
      scrollLeft = parent.clientWidth - 75
    } else {
      console.log('Scenario B');
      let currentIndex = Math.ceil(currentScrollLeft / parent.clientWidth)
      scrollLeft = ((currentIndex + 1) * parent.clientWidth) - (75 * (currentIndex + 1))
    }

    element.scrollLeft = scrollLeft
  }
  handleSelectChangeDate = selectedOptionDate => {
    this.setCreateInput('date', selectedOptionDate.value)
    this.setState(
      { selectedOptionDate },
      () => console.log(`Option selected:`, this.state.selectedOptionDate)
    );
  }
  handleSelectChangeTopic = selectedOptionTopic => {
    this.setCreateInput('topic', selectedOptionTopic.value)
    this.setState(
      { selectedOptionTopic },
      () => console.log(`Option selected:`, this.state.selectedOptionTopic)
    );
  }
  handleSelectChangeCategory = selectedOptionCategory => {
    this.setCreateInput('category', selectedOptionCategory.value)
    this.setState(
      { selectedOptionCategory },
      () => console.log(`Option selected:`, this.state.selectedOptionCategory)
    );
  }
  handleSelectChangeStartHour = selectedOptionStartHour => {
    this.setCreateInput('dateStartHour', selectedOptionStartHour.value)
    this.setState(
      { selectedOptionStartHour },
      () => console.log(`Option selected:`, this.state.selectedOptionStartHour)
    );
  }
  handleSelectChangeStartMinute = selectedOptionStartMinute => {
    this.setCreateInput('dateStartMinute', selectedOptionStartMinute.value)
    this.setState(
      { selectedOptionStartMinute },
      () => console.log(`Option selected:`, this.state.selectedOptionStartMinute)
    );
  }
  handleSelectChangeEndHour = selectedOptionEndHour => {
    this.setCreateInput('dateEndHour', selectedOptionEndHour.value)
    this.setState(
      { selectedOptionEndHour },
      () => console.log(`Option selected:`, this.state.selectedOptionEndHour)
    );
  }
  handleSelectChangeEndMinute = selectedOptionEndMinute => {
    this.setCreateInput('dateEndMinute', selectedOptionEndMinute.value)
    this.setState(
      { selectedOptionEndMinute },
      () => console.log(`Option selected:`, this.state.selectedOptionEndMinute)
    );
  }
  handleSelectChangeParticipants = selectedOptionParticipants => {
    this.setCreateInput('participants', selectedOptionParticipants)
    this.setState(
      { selectedOptionParticipants },
      () => console.log(`Option selected:`, this.state.selectedOptionParticipants)
    );
  }
  render() {
    return (
      <div className={`calendar-global-container ${this.state.isLoading ? `loading` : ``}`}>
        {/* Form controls */}
        <div className={`controls`}>
          <div className={`filter`}>
            <label>
              <strong>Timezone</strong>
              <Select
                onChange={ (chosen) => this.filter('category', chosen.value) }
                options={ this.state.categoryOptions }
              />
            </label>
            <label>
              <strong>Topic</strong>
              <Select
                onChange={ (chosen) => this.filter('topic', chosen.value) }
                options={ this.state.topicOptions }
              />
            </label>
            <label>
              <strong>Channel</strong>
              <Select
                onChange={ (chosen) => this.filter('channel', chosen.value) }
                options={ this.state.channelOptions }
              />
            </label>
            <label>
              <strong>Speaker</strong>
              <Select
                onChange={ (chosen) => this.filter('representative', chosen.value) }
                options={ this.state.representativeOptions }
              />
            </label>
            <label className={`simple`}>
              <button onClick={(ev) => { ev.stopPropagation(); this.setState({ showCreate: true }) }} className={`button secondary`}>
                Add Session
              </button>
            </label>
          </div>
        </div>

        {
          this.state.showCreate ?
            <form className={`form-create ${this.state.isCreateLoading ? `loading` : ``} ${this.state.isCreateSuccess ? `success` : ``}`} onSubmit={(ev) => this.create(ev)} onClick={(ev) => { ev.stopPropagation() }}>
              <button className={`close`} onClick={(ev) => { ev.preventDefault(); this.setState({ showCreate: false }) }}>Close</button>
              <div data-simplebar style={{ overflow: 'scroll' }}>
                <p className={`error ${this.state.createErrorMessage ? `show` : `hide` }`}>{ this.state.createErrorMessage }</p>
                <label className={`${this.state.erroneousFields.includes('title') ? `error-field` : ``}`}>
                  <strong>Session Name</strong>
                  <input type="text" name="text" defaultValue="" onChange={(ev) => this.setCreateInput('title', ev.currentTarget.value)} />
                </label>
                <label className={`${this.state.erroneousFields.includes('category') ? `error-field` : ``}`}>
                  <strong>Category</strong>
                  <Select
                    onChange={ this.handleSelectChangeCategory }
                    options={ this.state.selectCategories }
                  />
                </label>
                <label className={`${this.state.erroneousFields.includes('topic') ? `error-field` : ``}`}>
                  <strong>Topic</strong>
                  <Select
                    onChange={ this.handleSelectChangeTopic }
                    options={ this.state.selectTopics }
                  />
                </label>
                <label className={`${this.state.erroneousFields.includes('date') ? `error-field` : ``}`}>
                  <strong>Date</strong>
                  <Select
                    onChange={ this.handleSelectChangeDate }
                    options={ this.state.selectDays }
                  />
                </label>
                <div className={`double`}>
                  <div className={`${this.state.erroneousFields.includes('dateStartHour') || this.state.erroneousFields.includes('dateStartMinute') ? `error-field` : ``}`}>
                    <strong>Start</strong>
                    <span className={`time`}>
                      <label>
                        <Select
                          value={ this.state.selectedOptionStartHour }
                          onChange={ this.handleSelectChangeStartHour }
                          options={ this.state.selectHours }
                        />
                      </label>
                      :
                      <label>
                        <Select
                          value={ this.state.selectedOptionStartMinute }
                          onChange={ this.handleSelectChangeStartMinute }
                          options={ this.state.selectMinutes }
                        />
                      </label>
                    </span>
                  </div>
                  <div className={`${this.state.erroneousFields.includes('dateEndHour') || this.state.erroneousFields.includes('dateEndMinute') ? `error-field` : ``}`}>
                    <strong>End</strong>
                    <span className={`time`}>
                      <label>
                        <Select
                          value={ this.state.selectedOptionEndHour }
                          onChange={ this.handleSelectChangeEndHour }
                          options={ this.state.selectHours }
                        />
                      </label>
                      :
                      <label>
                        <Select
                          value={ this.state.selectedOptionEndMinute }
                          onChange={ this.handleSelectChangeEndMinute }
                          options={ this.state.selectMinutes }
                        />
                      </label>
                    </span>
                  </div>
                </div>
                <label className={`${this.state.erroneousFields.includes('participants') ? `error-field` : ``}`}>
                  <strong>Participants</strong>
                  <Select
                    value={ this.state.selectedOptionParticipants }
                    onChange={ this.handleSelectChangeParticipants }
                    options={ this.state.selectParticipants }
                    isMulti={ true }
                  />
                </label>
                <label className={`${this.state.erroneousFields.includes('description') ? `error-field` : ``}`}>
                  <strong>Description</strong>
                  <textarea rows={4} type="text" name="text" defaultValue="" onChange={(ev) => this.setCreateInput('description', ev.currentTarget.value)} />
                </label>
                <label className={`button-label`}>
                  <button className={`button active`} type="submit">Create Session</button>
                </label>
              </div>
            </form>
          :
          null
        }

        {/* Table view */}
        <div className={`calendar-parent`} id={`calendar-parent`}>
          <button className={`nav-button previous`} onClick={(ev) => this.previous()}>Previous</button>
          <button className={`nav-button next`} onClick={(ev) => this.next()}>Next</button>
          <div className={`calendar-inner`} id={`calendar-inner`}>
            <div className={`calendar-container`} id={`calendar-container`} onClick={(ev) => { this.setSelectedEvent({ ID: null }) }}>
              { this.state.currentView }
              <div className={`calendar-gutter`}><div style={{ display: 'block', minWidth: 50, color: 'white'}}>1</div></div>
            </div>
          </div>
        </div>

        {/* NEW */}
        <div id="dp"></div>
      </div>
    );
  }
}

export default App;


Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}